<template>
  <main style="padding-top: 100px">
    <div class="page_header border-bottom element_to_stick">
      <b-container>
        <b-row>
          <b-col md="12">
            <h1>Protectia Datelor / GDPR</h1>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-container class="margin_30_20">
      <b-row>
        <b-col md="12"> Protectia Datelor </b-col>
        <div class="main-wrapper">
          <div class="page messages">
            <div data-placeholder="messages"></div>
            <div data-bind="scope: 'messages'">
              <!-- ko if: cookieMessages && cookieMessages.length > 0 --><!-- /ko --><!-- ko if: messages().messages && messages().messages.length > 0 --><!-- /ko -->
            </div>
          </div>
          <div class="columns">
            <div class="column main">
              <input name="form_key" type="hidden" value="igZl5wCcEPUSOKTy" />
              <div
                id="authenticationPopup"
                data-bind="scope:'authenticationPopup'"
                style="display: none"
              >
              </div>
              <p style="text-align: center">
                <b
                  ><span style="text-decoration: underline"
                    >ACORD PRELUCRARE ŞI STOCARE DATE CU CARACTER PERSONAL</span
                  ></b
                >
              </p>
              <p style="text-align: left">
                Prin vizitarea site-ului www.coressy.com , atunci când completaţi
                formularul de contact sau prin accesul la serviciile sau
                produsele pe care le punem la dispoziţie, ne furnizaţi
                informaţii personale. Pe această pagină aflaţi ce date sunt
                colectate şi care este scopul colectării şi/sau prelucrarii
                acestora. Astfel iţi exprimi acordul fără echivoc, că eşti
                informat cu privire la stocarea şi prelucrarea datelor personale
                citind această pagină. Acordul dumneavoastră este liber
                consimţit şi fără vreo condiţionare din partea noastră.
              </p>
              <p style="text-align: left">
                Încă de la început dorim să vă asigurăm că nu punem la
                dispoziţia terţilor liste cu adrese de e-mail în scopul
                obţinerii de foloase materiale. Fiind vorba de informaţii
                personale, facem tot posibilul ca ele să fie întotdeauna în
                siguranţă. Dacă va trebui să oferim informaţii unor terţe părţi
                te vom informa înainte. De asemenea te rugăm să citeşti cu
                atenţie acest acord.
              </p>
              <p style="text-align: left">
                Site-ul www.coressy.com este o societate comercială şi este
                deţinută şi administrată de SC. ILASC DELIVERY SRL cu sediul în Dumbrava Rosie, judetul Neamt , având C.U.I. 39989669 , înregistrată la Registrul
                Comerţului sub numărul&nbsp;J27/970/2018
              </p>
              <p style="text-align: left">
                Site-ul www.coressy.com &nbsp;devine operator de date cu caracter
                personal în conformitate cu Legea nr. 677 din 2001 şi cu
                Regulamentul&nbsp; UE &nbsp;679/2016 (ce se aplica şi în România
                începând cu data de 25 mai 2018).
              </p>
              <p style="text-align: left">
                În conformitate cu dispoziţiile legale menţionate anterior, ca
                vizitator devii persoană vizată. Asta înseamnă o persoană fizică
                ce poate fi identificată prin nume sau date de localizare ca
                adresă IP sau geolocaţie, număr de telefon.
              </p>
              <p style="text-align: left">
                În general colectarea de date de identificare (de tip IP sau
                geolocaţie) se face în scopul de a oferi o experienţă de
                browsing îmbunătăţită şi pentru statistici în vederea
                îmbunătăţirii prezenţei în mediul online a site-ului. Aceasta se
                face prin colectarea de cookie-uri sau prin alte metode tehnice
                specific şi nu permite întotdeauna identificarea individului
                (asocierea unui cookie cu o persoană).
              </p>
              <p style="text-align: left">
                Orice modificare a termenilor sau condiţiilor prezentate mai sus
                va fi adusă la cunoştinţă printr-o notificare trimisă prin
                e-mail.
              </p>
              <p style="text-align: left">
                Informaţiiile utilizatorului, pot fi furnizate la cerere către
                Poliţie, Instanţe Judecătoreşti şi Parchetul General dar şi unor
                instituţii şi organe abilitate conform legii, în urma unei
                cereri depuse de acestea, în condiţiile legii.
              </p>
              <p style="text-align: left">
                Care sunt drepturile tale şi cum pot fi exercitate?
              </p>
              <p style="text-align: left">
                Pe lângă drepturile existente, de la data aplicării
                Regulamentului beneficiezi de drepturi adiţionale. În cele ce
                urmează, se regăsesc, enumerate, drepturile pe care le ai:
              </p>
              <p style="text-align: left">
                <b>Dreptul la informare</b>&nbsp;– poţi solicita informaţii
                privind activităţile de prelucrare a datelor tale personale;
              </p>
              <p style="text-align: left">
                <b>Dreptul la rectificare</b>&nbsp;– poti rectifica datele
                personale inexacte sau le poţi completa;
              </p>
              <p style="text-align: left">
                <b>Dreptul la ştergerea datelor (“dreptul de a fi uitat”)</b
                >&nbsp;– poţi obţine ştergerea datelor, în cazul în care
                prelucrarea acestora nu a fost legală sau în alte cazuri
                prevăzute de lege;
              </p>
              <p style="text-align: left">
                <b>Dreptul la restricţionarea prelucrării</b>&nbsp;– poţi
                solicita restricţionarea prelucrării în cazul în care conteşti
                exactitatea datelor, precum şi în alte cazuri prevăzute de lege;
              </p>
              <p style="text-align: left">
                <b>Dreptul de opoziţie</b>&nbsp;– poţi să te opui, în special,
                prelucrărilor de date care se întemeiază pe interesul nostru
                legitim;
              </p>
              <p style="text-align: left">
                <b>Dreptul la portabilitatea datelor</b>&nbsp;– poţi primi, în
                anumite condiţii, datele personale pe care ni le-ai furnizat,
                într-un format care poate fi citit automat sau poţi solicita ca
                respectivele date să fie transmise altui operator în termen de
                60 de zile
              </p>
              <p style="text-align: left">
                <b>Dreptul de a depune plângere</b>&nbsp;– poţi depune plângere
                faţă de modalitatea de prelucrare a datelor personale la
                Autoritatea Naţională de Supraveghere a Prelucrării Datelor cu
                Caracter Personal;
              </p>
              <p style="text-align: left">
                <b>Dreptul de retragere a consimţământului</b>&nbsp;– în
                cazurile în care prelucrarea se întemeiază pe consimţământul
                tău, ţi-l poţi retrage oricând. Retragerea consimţământului va
                avea efecte doar pentru viitor, prelucrarea efectuată anterior
                retragerii rămânând în continuare valabilă;
              </p>
              <p style="text-align: left">
                <b>Drepturi suplimentare aferente deciziilor automate</b>: poţi
                cere şi obţine intervenţia umana cu privire la respectiva
                prelucrare, îţi poţi exprima propriul punct de vedere cu privire
                la aceasta şi poţi contesta decizia. Pentru aţi exercita aceste
                drepturi, poţi trimite oricând un e-mail cu solicitările la
                adresa de e-mail: contact@coressy.com
              </p>
            </div>
          </div>
        </div>
      </b-row>
    </b-container>
  </main>
</template>

<script>
export default {
  name: "GDPR",
  data() {
    return {
      isBusy: false,
    };
  },
};
</script>
